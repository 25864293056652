<template>
  <div class="pole-main">
    <div class="topPolice">
      <div class="police-item">
        <img :src="img.lightMainAlarmFetal">
        <div class="police-title-num">
          <span class="grey">严重报警</span><span class="no-opacity">220<i class="grey">个</i></span>
        </div>
      </div>
      <div class="police-item">
        <img :src="img.lightMainAlarmCommon">
        <div class="police-title-num">
          <span class="grey">普通报警</span><span class="no-opacity">220<i class="grey">个</i></span>
        </div>
      </div>
      <div class="police-item">
        <img :src="img.lightMainAlarmPre">
        <div class="police-title-num">
          <span class="grey">预报警</span><span class="no-opacity">220<i class="grey">个</i></span>
        </div>
      </div>
    </div>
    <div class="census">
      <div class="census-list">
        <div class="census-title">欠压</div>
        <div class="census-than">
          <div class="census-value" style="width:180px;">
            <img :src="img.pole.homePage.circular" alt="">
          </div>
        </div>
      </div>
      <div class="census-list">
        <div class="census-title">过压</div>
        <div class="census-than">
          <div class="census-value" style="width: 250px;">
            <img :src="img.pole.homePage.circular" alt="">
          </div>
        </div>
      </div>
      <div class="census-list">
        <div class="census-title">灭灯</div>
        <div class="census-than">
          <div class="census-value" style="width: 153px;">
            <img :src="img.pole.homePage.circular" alt="">
          </div>
        </div>
      </div>
      <div class="census-list">
        <div class="census-title">过温</div>
        <div class="census-than">
          <div class="census-value" style="width: 53px;">
            <img :src="img.pole.homePage.circular" alt="">
          </div>
        </div>
      </div>
      <div class="census-list">
        <div class="census-title">水浸</div>
        <div class="census-than">
          <div class="census-value" style="width: 233px;">
            <img :src="img.pole.homePage.circular" alt="">
          </div>
        </div>
      </div>
      <div class="census-list">
        <div class="census-title"></div>
        <div class="census-number">
          <template v-for="el in 45">
            <div :key="el" v-if="el%5==0">
              {{el}}
            </div>
          </template>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'policePage',
  components: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {},
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
}
.topPolice {
  display: flex;
  justify-content: space-evenly;
}
.police-item {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.police-title-num {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b2c6e2;
  margin-left: 5px;
}
.grey {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  font-style: inherit;
}
.no-opacity {
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.no-opacity .grey {
  margin-left: 5px;
  font-size: 14px;
}
.census {
  display: flex;
  flex-direction: column;
  padding: 15px 20px 0;
}
.census-list {
  display: flex;
  margin-bottom: 7px;
}
.census-title {
  width: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b2c6e2;
}
.census-than {
  width: 293px;
  height: 5px;
  background: #1c2240;
  margin: auto;
}
.census-value {
  height: 5px;
  background: linear-gradient(90deg, #0d1120, #5dbcff);
  position: relative;
}
.census-value img {
  position: absolute;
  right: -10px;
  top: -6px;
}
.census-number {
  display: flex;
  width: 293px;
  justify-content: space-between;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e99;
}
</style>