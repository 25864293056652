<template>
  <div class="space-box">
    <div class="top">
      <div class="title">空气质量：<span>优</span></div>
      <div class="address"><img :src="img.lightMainLocation" alt=""> 深圳</div>
    </div>
    <div class="con">
      <div class="left">
        <div id="main"></div>
        <div class="unit">AOI</div>
        <div class="value">20</div>
        <p class="pollute">首要污染：PM10</p>
      </div>
      <div class="right">
        <div class="norm">
          <div class="norm-title">PM<i class="min">10</i></div>
          <div class="norm-size">61<span>μg/m3</span></div>
          <div class="norm-many">
            <div class="norm-than"></div>
          </div>
        </div>
        <div class="norm">
          <div class="norm-title">PM<i class="min">10</i></div>
          <div class="norm-size">61<span>μg/m3</span></div>
          <div class="norm-many">
            <div class="norm-than"></div>
          </div>
        </div>
        <div class="norm">
          <div class="norm-title">SO<i class="min">2</i></div>
          <div class="norm-size">61<span>μg/m3</span></div>
          <div class="norm-many">
            <div class="norm-than"></div>
          </div>
        </div>
        <div class="norm">
          <div class="norm-title">NO<i class="min">2</i></div>
          <div class="norm-size">61<span>μg/m3</span></div>
          <div class="norm-many">
            <div class="norm-than"></div>
          </div>
        </div>
        <div class="norm">
          <div class="norm-title">O<i class="min">3</i></div>
          <div class="norm-size">61<span>μg/m3</span></div>
          <div class="norm-many">
            <div class="norm-than"></div>
          </div>
        </div>
        <div class="norm">
          <div class="norm-title">CO</div>
          <div class="norm-size">61<span>μg/m3</span></div>
          <div class="norm-many">
            <div class="norm-than"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: 'workPage',
  components: {
  },
  data() {
    return {
      many: 20,
      chart: null,
      chartDom: null,
      option: {
        series: [
          {
            type: 'gauge',
            min: 0,
            max: 100,
            radius: '95%',
            itemStyle: {
              color: '#00FFCC',
              shadowBlur: 12,
              shadowOffsetX: 1,
              shadowOffsetY: 1
            },
            splitLine: {
              show: false
            },
            axisTick: {
              distance: 0,
              length: 5,
              lineStyle: {
                color: '#00CCFF',
                width: 1
              }
            },
            progress: {
              show: true,
              width: 10
            },
            axisLabel: {
              distance: -1,
              color: '#00CCFF',
              fontSize: 11
            },
            pointer: {
              show: false
            },
            detail: {
              width: '100%',
              lineHeight: 40,
              height: 40,
              borderRadius: 8,
              offsetCenter: [0, '10%'],
              valueAnimation: true,
              formatter: function () {
                // console.log(value)
                return '{value|优}';
              },
              rich: {
                value: {
                  fontSize: 33,
                  color: '#00FFCC'
                },
              }
            },
            data: [
              {
                value: 20
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
  },
  watch: {
    data() {
      this.initOption();
    },
  },
  mounted: function () {
    this.initOption();
  },
  methods: {
    initOption() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        this.chartDom = document.getElementById("main");
        this.chart = echarts.init(this.chartDom);
        this.chart.setOption(this.option);
      });
    },
  },
}
</script>
<style scoped>
.space-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  display: flex;
  justify-content: space-between;
}
.top .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  line-height: 46px;
  margin-left: 25px;
}
.top .title span {
  color: #00ffcc;
}
.address {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5293f5;
  line-height: 46px;
  margin-right: 25px;
}
.con {
  width: 100%;
  height: 200px;
  display: flex;
}
.con .left {
  width: 180px;
  height: 150px;
  position: relative;
}
.left .unit {
  position: absolute;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00eaff;
  left: 43%;
  top: 67%;
}
.left .pollute {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00ccff;
  line-height: 46px;
  text-align: center;
}
.left .value {
  position: absolute;
  font-size: 27px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
  left: 41%;
  top: 74%;
}
#main {
  width: 100%;
  height: 150px;
}
.right {
  width: 200px;
}
.norm {
  margin-right: 28px;
  float: left;
  margin-bottom: 25px;
}
.norm-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00ccff;
  line-height: 10px;
}
.min {
  font-size: 12px;
  font-style: inherit;
}
.norm-size {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.norm-size span {
  font-size: 14px;
  color: #00ccff;
  margin-left: 3px;
  vertical-align: top;
}
.norm-many {
  width: 70px;
  height: 5px;
  background: #1e3262;
  border-radius: 3px;
}
.norm-than {
  width: 30%;
  height: 5px;
  background: #7affd0;
  border-radius: 3px;
}
</style>