<template>
  <div class="popup-police">
    <div class="police-left">
      <div class="police-video-box">
        <div id="vehicleId" class="police-video-player" :key="timer"></div>
      </div>
      <div class="police-video-butList">
        <div>
          <a href="javascript:void(0);" class="police-video-but video-blue"><img :src="img.light.police.phone" alt="">对讲</a>
          <a href="javascript:void(0);" class="police-video-but video-orange"><img :src="img.light.police.phone" alt="">对讲</a>
        </div>
        <div>
          <a href="javascript:void(0);" class="police-video-but video-blue ml20"><img :src="img.light.police.see" alt="">查看</a>
          <a href="javascript:void(0);" class="police-video-but video-blue ml20"><img :src="img.light.police.ellipse" alt="">录制</a>
          <a href="javascript:void(0);" class="police-video-but video-blue ml20"><img :src="img.light.police.screenshot" alt="">截图</a>
        </div>
      </div>
    </div>
    <div class="police-right">
      <div class="popup-police-title"><i></i>当前连接设备</div>
      <div class="police-list">
        <div class="police-list-box" v-for="item in 10" :key="item">
          <span class="police-list-title" :title="listData[0].title">{{listData[0].title}}</span>
          <span class="police-list-type">{{listData[0].type}}</span>
          <span class="police-list-tiem">{{listData[0].time}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EZUIKit from 'ezuikit-js';
export default {
  name: 'spacePage',
  components: {
  },
  props: {
  },
  data() {
    return {
      devices: [],
      listData: [{ title: "龙华街道2号灯杆", type: "灭灯", time: "20220718 16:26" }],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
    this.getVideos();
  },
  methods: {
    getVideos: function () {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleVideo`, {groupId, stationId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        res.data.map(p => {
          if(!p.content){
            p.content = {};
          }
          if(!p.content.tasks){
            p.content.tasks = [];
          }
        })
        this.$set(this, 'devices', res.data);
        this.urlAjax();
      })
    },
    urlAjax: async function () {
      if (this.devices.length <= 0) { return false }
      let ajaxData = this.devices[0];
      this.player = null;
      let res = await this.$axios.get(`//${this.domains.trans}/video/preview/${ajaxData.id}?code=${ajaxData.content.code}`);
      this.timer = new Date().getTime();
      if (res.code !== 0) {
        return;
      }
      setTimeout(() => {
        let id = "vehicleId";
        let v = document.getElementById(id);
        let token = res.data.token;
        let url = res.data.url;
        let rect = v.getBoundingClientRect();
        this.player = new EZUIKit.EZUIKitPlayer({
          id: id,
          accessToken: token,
          url: url,
          autoplay: true,
          width: rect.width,
          height: rect.height,
        });
        v.style.overflow = 'hidden';
      }, 100);
    },
  },
}
</script>
<style scoped>
.popup-police {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
}
.police-left {
  width: 755px;
  height: 540px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.police-right {
  width: 353px;
  height: 540px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.popup-police-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.popup-police-title i {
  width: 8px;
  height: 8px;
  background: #3080f5;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.police-list-box {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
  margin-top: 18px;
}
.police-list-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  width: 107px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.police-list-type {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fd5353;
}
.police-list-tiem {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.police-video-butList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.police-video-but {
  width: 72px;
  height: 34px;
  border-radius: 6px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 34px;
  display: inline-block;
}
.police-video-but img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}
.video-blue.ml20 {
  margin-left: 20px;
}
.video-blue {
  background: linear-gradient(180deg, #4890fb, #025ee5);
}
.video-orange {
  background: linear-gradient(180deg, #fd6357, #d03c31);
  margin-left: 20px;
}
.police-video-box {
  width: 755px;
  height: 487px;
}
.police-video-player {
  width: 100%;
  height: 100%;
}
</style>