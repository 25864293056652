<template>
  <div class="pole-main">
    <chartsPie :option="chartsPieData1" id="workOrder" :key="timer" v-if="isShow"></chartsPie>
    <div class="title">
      <div class="typeRight">
        <div class="type"><i class="sys1"></i>待接警</div>
        <div class="type"><i class="sys2"></i>故障判定</div>
      </div>
      <div class="typeRight">
        <div class="type"><i class="sys3"></i>待审核</div>
        <div class="type"><i class="sys4"></i>正在处理</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'spacePage',
  components: {
  },
  data() {
    return {
      timer: "",
      isShow: true,
      chartsPieData1: {
        color: ["#64f3f6", "#389dff", "#e4ca30", "#576ffc"],
        series: [
          { value: 40, name: "40", itemStyle: { color: "#64f3f6" }, label: { color: "#64f3f6" } },
          { value: 30, name: "30", itemStyle: { color: "#389dff" }, label: { color: "#389dff" } },
          { value: 20, name: "20", itemStyle: { color: "#e4ca30" }, label: { color: "#e4ca30" } },
          { value: 60, name: "60", itemStyle: { color: "#576ffc" }, label: { color: "#576ffc" } },
        ],
        setSeries: {
          type: "pie",
          radius: [50, 80],
          center: ["50%", "50%"],
          roseType: "area",
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {},
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
}
#workOrder {
  width: 100%;
  height: calc(100% - 50px);
}
.title {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
}
.type {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  line-height: 18px;
}
.type i {
  display: inline-block;
  width: 10px;
  height: 6px;
  vertical-align: middle;
  margin-right: 8px;
}
.sys1 {
  background: #64f3f6;
}
.sys2 {
  background: #389dff;
}
.sys3 {
  background: #e4ca30;
}
.sys4 {
  background: #576ffc;
}
</style>