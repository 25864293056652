<template>
  <div class="pole-main">
    <div class="list">
      <div class="video-box">
        <img :src="testImg" class="video" />
        <div class="block">
          <span>龙岗街道</span>
          <img :src="img.pole.homePage.screen" alt="" @click="showModal()">
        </div>
      </div>
      <div class="video-box">
        <img :src="testImg" class="video" />
        <div class="block">
          <span>龙岗街道</span>
          <img :src="img.pole.homePage.screen" alt="" @click="showModal()">
        </div>
      </div>
    </div>
    <div class="list">
      <div class="video-box">
        <img :src="testImg" class="video" />
        <div class="block">
          <span>龙岗街道</span>
          <img :src="img.pole.homePage.screen" alt="" @click="showModal()">
        </div>
      </div>
      <div class="video-box">
        <img :src="testImg" class="video" />
        <div class="block">
          <span>龙岗街道</span>
          <img :src="img.pole.homePage.screen" alt="" @click="showModal()">
        </div>
      </div>
    </div>
    <Modal v-model="isShow" title="监控视频" @on-ok="ok" @on-cancel="cancel">
      <img :src="testImg" class="video" />
      <!-- <video width="100%" controls autoplay>
        <source :src="videoUrl" type="video/mp4" />
      </video> -->
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'spacePage',
  components: {
  },
  data() {
    return {
      testImg: require("@/assets/video-1.jpeg"),
      isShow: false,
      videoUrl: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3"
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    showModal() {
      this.videoUrl = "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3"
      this.isShow = true;
    },
    ok() {
      this.videoUrl = "";
      this.isShow = false;
    },
    cancel() {
      this.videoUrl = "";
      this.isShow = false;
    },
  },
}
</script>
<style scoped>
.pole-main {
  width: calc(100%);
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.list {
  display: flex;
  justify-content: space-evenly;
  justify-content: space-between;
  padding: 0 20px;
}
.video-box {
  width: 168px;
  height: 88px;
  border: 1px solid #283866;
  position: relative;
}
.video {
  width: 100%;
  height: 100%;
}
.block {
  position: absolute;
  width: calc(100%);
  height: 21px;
  background: #000000;
  opacity: 0.5;
  bottom: 0px;
  padding: 0 10px;
}
.block span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.block img {
  width: 12px;
  height: 12px;
  display: block;
  float: right;
  margin-top: 4px;
  cursor: pointer;
}
</style>