<template>
  <div class="pole-main" id="poleMain">
    <gisPage />
    <div class="warnBox" v-if="warnList.length>0" :style="{backgroundImage: `url(${img.pole.homePage.warnBg})`}">
      <img :src="img.pole.homePage.warn" alt="" class="warn-img">
      <marquee width="100%" class="warn-list">
        <template v-for="(el, idx) in warnList">
          <span :key="idx">{{`${el.time}${el.address}${el.name}`}}</span>
        </template>
      </marquee>
    </div>
    <div class="left-but">
      <div v-for="(el, idx) in leftNav" :key="idx" :style="{backgroundImage: `url(${img.pole.homePage.middleBut1})`}" class="nav-list"><span>{{el.name}}</span></div>
    </div>
    <div class="right-but">
      <div v-for="(el, idx) in rightNav" :key="idx" :style="{backgroundImage: `url(${img.pole.homePage.middleBut1})`}" class="nav-list"><span>{{el.name}}</span></div>
    </div>
    <div class="bottom-but">
      <template v-for="el in effectList ">
        <div :style="{backgroundImage: `url(${img.pole.homePage.effect})`}" :key="el.key" :title="el.name" class="effect-list" @click="effectListBut(el)">
          <img :src="el.url" alt="">
        </div>
      </template>
    </div>
    <Modal v-model="isShwoEffect" :title="effect.name" class="polePopup" :width="effect.width" :mask-closable="false">
      <popupIndex :isKey="isEffectKey"></popupIndex>
      <div slot="footer">
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import popupIndex from './popup/index'
import gisPage from './gis.vue'
export default {
  name: 'Home',
  components: {
    popupIndex,
    gisPage
  },

  data() {
    return {
      warnList: [
        { name: "灯杆欠压", type: "一级", time: "2022年5月25日 18:12", address: "松苑路兴华街2号" },
        { name: "灯杆欠压", type: "一级", time: "2022年5月25日 18:12", address: "松苑路兴华街2号" },
        { name: "灯杆欠压", type: "一级", time: "2022年5月25日 18:12", address: "松苑路兴华街2号" },
        { name: "灯杆欠压", type: "一级", time: "2022年5月25日 18:12", address: "松苑路兴华街2号" },
      ],
      effectList: [
        { name: "智慧灯杆", key: "s1", url: this.img.pole.homePage.effectLight, width: "1165" },
        { name: "安防监控 ", key: "s2", url: this.img.pole.homePage.effectVideo, width: "1430" },
        { name: "公共广播", key: "s3", url: this.img.pole.homePage.effectRadio, width: "1100" },
        { name: "智慧告警", key: "s4", url: this.img.pole.homePage.effectPolice, width: "1165" },
        { name: "信息屏", key: "s5", url: this.img.pole.homePage.effectInfo, width: "900" },
        { name: "环境温湿度", key: "s6", url: this.img.pole.homePage.effectHumidity, width: "900" },
        { name: "智慧Wifi", key: "s7", url: this.img.pole.homePage.effectWifi, width: "1170" },
      ],
      leftNav: [
        { name: "公共广播", url: "" },
        { name: "一键报警", url: "" },
        { name: "广告屏", url: "" },
        { name: "环境监测", url: "" },
      ],
      rightNav: [
        { name: "无线管理", url: "" },
        { name: "充电桩", url: "" },
        { name: "智慧井盖", url: "" },
        { name: "智慧垃圾桶", url: "" },
      ],
      effect: {
        name: "智慧灯杆",
        key: "s1",
        width: "1129"
      },
      isEffectKey: "",
      isShwoEffect: false,
      isShwoName: "",
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    effectListBut(el) {
      this.isShwoEffect = true;
      Object.assign(this.effect, el);
      this.isEffectKey = el.key;
    },
    cancel() {

    },
    ok() {

    },
  }
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
  position: relative;
}
.warnBox {
  width: 504px;
  height: 34px;
  background: rgba(211, 76, 86, 0.3);
  position: absolute;
  top: 2px;
  left: 50%;
  margin-left: -250px;
  display: flex;
  padding: 7px 15px;
}
.warn-img {
  width: 18px;
  height: 16px;
  margin-right: 10px;
}
.warn-list {
  display: flex;
}
.warn-list span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  margin-left: 18px;
}
.warn-list span:first-child {
  margin-left: 0;
}
.bottom-but {
  width: 96%;
  position: absolute;
  bottom: 25px;
  display: flex;
  justify-content: space-evenly;
  margin-left: 2%;
}
.effect-list {
  width: 66px;
  height: 66px;
  text-align: center;
  line-height: 66px;
  transition: all 0.5s;
  cursor: pointer;
}
.effect-list:hover {
  transform: scale(1.12);
}
.effect-list img {
  vertical-align: middle;
}
.left-but {
  width: 45px;
  position: absolute;
  left: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.nav-list {
  width: 100%;
  height: 130px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-list span {
  width: 15px;
  font-size: 16px;
  font-family: titleBody;
  font-weight: 400;
  color: #cdeffa;
  display: flex;
  margin: 0 auto;
  line-height: 16px;
}
.right-but {
  width: 45px;
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.right-but .nav-list {
  transform: rotate(180deg);
}
.right-but .nav-list span {
  transform: rotate(180deg);
}

::v-deep .ivu-modal .ivu-modal-content {
  background: linear-gradient(
    136deg,
    rgba(22, 77, 150, 0.85),
    rgba(6, 36, 80, 0.85)
  );
}
::v-deep .ivu-modal .ivu-modal-header {
  border-bottom: none;
  padding: 14px 16px 0;
}
::v-deep .ivu-modal .ivu-modal-header-inner {
  color: #fff;
}
::v-deep .ivu-modal .ivu-modal-footer {
  border-top: none;
  display: none;
}
</style>